.app-container {
    padding: 80px 0 0 250px;
}

.p-container {
    padding: 1.5em;
}

@media screen and (max-width: 1320px) {
    .app-container {
        padding: 80px 0 0 100px;
    }

    .p-container {
        padding: 1em;
    }
}

@media screen and (max-width: 767px) {
    .app-container {
        padding: 80px 0 80px 0;
    }

    .p-container {
        padding: 1em 0;
    }
}

.auto-grid {
    --auto-grid-min-size: 350px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    grid-gap: 1.5rem;
    padding: 0;
}

@media screen and (max-width: 767px) {
    .auto-grid {
        --auto-grid-min-size: 100%;
        padding: 0 1em;
    }
}

.main-clr {
    color:#005386;
}
.main-clr-b {
    color:white;
    background-color: #005386;
}

.fab-add {
    cursor: pointer;
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    color: #005386;
    font-size: 1.2em;
    font-weight: bold;
    padding: 1rem 1.5rem;
    margin: 0 3rem 3rem 0;
    border-radius: 30px;
    box-shadow: 0 0 25px 5px rgba(0,0,0,.1);
}

@media screen and (max-width: 767px) {
    .fab-add {
        margin: 0 1.5rem 7rem 0;
    }
}

@media screen and (max-width: 500px){
    .fab-add {
        margin: 0 1rem 6rem 0;
    }
}

.tui-editor-contents {
    font-size: 14px !important;
    /*word-break: keep-all;*/
}

.ui.modal {
    border-radius: 20px !important;
}

.ui.modal>.icon:first-child+*, .ui.modal>:first-child:not(.icon) {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.ui.modal>:last-child {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img {
    margin-left: 0.2em;
    border: 1px solid #eaeaea;
}

.ui.search.dropdown, .ui.selection.dropdown {
    border-radius: 10px !important;
}

.ui.selection.active.dropdown .menu {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ui.upward.selection.dropdown .menu {
    border-radius: 10px 10px 0 0;
}

.ui.upward.selection.dropdown.visible {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.ui.active.upward.selection.dropdown {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}



.ui.horizontal.divider:after, .ui.horizontal.divider:before {
    top: 0 !important;
}

input {
    border-radius: 10px !important;
}

textarea {
    border-radius: 10px !important;
}

.ui.button {
    border-radius: 15px !important;
    padding: 1rem 1.5rem !important;
}

/*table {*/
/*    border-radius: px !important;*/
/*    overflow: hidden !important;*/
/*}*/

.toastui-editor-defaultUI {
    border-radius: 10px !important;
}

.toastui-editor-defaultUI .toastui-editor-md-tab-container {
    border-top-left-radius: 10px !important;
}
.toastui-editor-defaultUI-toolbar {
    border-radius: 3px 10px 0 0 !important;
}

.ProseMirror {
    height: 100% !important;
}