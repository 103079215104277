.p-preview-swiper {
    padding: 0 1rem
}

.fab-market-chat {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 0;
    background: white;
    color: #005386;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 3rem 3rem 0;
    border-radius: 30px;
    box-shadow: 0 0 25px 5px rgba(0,0,0,.1);
}

.fab-chat-icon {
    width: 60px;
    height: 60px;
    background: #005386;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 1.5rem;
    border-radius: 30px;
}

@media screen and (max-width: 767px) {
    .p-preview-swiper {
        padding: 0;
    }

    .fab-market-chat {
        margin: 0 1.5rem 7rem 0;
    }
}

@media screen and (max-width: 500px){
    .fab-market-chat {
        margin: 0 1rem 6rem 0;
    }
}

.ui.progress .bar {
    min-width: 0 !important;
}