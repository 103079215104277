@media all and (min-width: 769px) {
    .next-btn {
        position: absolute;
        border-radius: 2rem;
        color: #005386;
        background: #f8f9fa;
        transition: all .2s ease-in-out;
        position: absolute;
        top: 38%;
        cursor: pointer;
        z-index: 1;
        right: -10px;
    }
}

@media all and (max-width: 768px) {
    .next-btn {
        display: none !important;
    }
}
.next-btn:hover {
     transform: scale(1.2);
 }

@media all and (min-width: 769px) {
    .prev-btn {
        border-radius: 2rem;
        color: #005386;
        background: #f8f9fa;
        transition: all .2s ease-in-out;
        position: absolute;
        top: 38%;
        cursor: pointer;
        z-index: 1;
        left: -10px;
    }
}

@media all and (max-width: 768px) {
    .prev-btn {
        display: none !important;
    }
}

.prev-btn:hover {
    transform: scale(1.2);
}


.p-round-btn {
    border-radius: 2rem;
    transition: all .2s ease-in-out;
    background-color: #eeeeee;
}
.p-round-btn:hover {
    background-color: gainsboro;
}

.slick-list {
    overflow: visible !important;
}
