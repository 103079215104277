.archive-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    padding: 2rem;
    color: #eeeeee;
    font-weight: bold;
    font-size: 2em;
    background-color: rgba(0,0,0, 0.8);
}