.p-sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 80px;
    bottom: 0;
    left: 0;
    background: #ffffffdd;
    overflow-x: hidden;
    flex: 1;
    border-right: 1px #eaeaea solid;
    box-shadow: 0 0 10px 0 #00000010;
}

.p-sidebar-desktop {
    width: 250px;
}

.p-sidebar-tablet {
    width: 100px
}

.p-sidebar-mobile {
    position: fixed;
    bottom: 0;
    padding: 20px 30px;
    width: 100%;
    height: 80px;
    background: #fff;
    border-top: 1px #eaeaea solid;
    z-index: 6;
}

.p-item {
    padding: 10px;
    color: #005386;
    border-radius: 5px;
    font-size: 1.1em;
    margin-bottom: 0.5rem;
}

a {
    outline:none;
    -webkit-tap-highlight-color: transparent !important;
}

.p-item-mobile {
    margin: 0 1em;
}

@media screen and (max-width: 500px) {
    .p-item-mobile {
        margin: 0 0.5em;
    }
    .p-sidebar-mobile {
        height: 70px;
        padding: 15px 30px;
    }
}

.p-item:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}

.p-navbar {
    position: fixed;
    padding: 20px 30px;
    width: 100%;
    height: 80px;
    background: #ffffffdd;
    border-bottom: 1px #eaeaea solid;
    z-index: 6;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 0 10px 0 #00000010;
}