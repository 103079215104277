.ui.secondary.pointing.menu {
    border-bottom: 2px solid transparent !important;
}

.channel-info-box {
    padding: 1rem 4.5rem;
}

@media screen and (max-width: 767px) {
    .channel-info-box {
        padding: 0.5rem 0;
    }
}

.tui-editor-contents {
    font-size: 14px;
    /*word-break: keep-all;*/
}

.fab-channel-chat {
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #005386;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    padding: 1rem 1.5rem;
    margin: 0 3rem 3rem 0;
    border-radius: 30px;
    box-shadow: 0 0 25px 5px rgba(0, 0, 0, .1);
}

@media screen and (max-width: 767px) {
    .fab-channel-chat {
        margin: 0 1.5rem 7rem 0;
    }
}

@media screen and (max-width: 500px) {
    .fab-channel-chat {
        margin: 0 1rem 6rem 0;
    }
}

.p-sub-btn {

}